import styled from '@emotion/styled';
import tw from 'twin.macro';

import { Counter } from '../../../components/counter';

const Container = styled.article`
  ${tw`flex flex-col h-auto items-center`}

  & h1 {
    ${tw`text-white text-4xl text-center font-light leading-tight w-11/12 pt-2`}

    & b {
      ${tw`font-bold`}
    }
  }
`;

const CounterStyled = styled(Counter)`
  margin-top: calc(${tw`mt-2`.marginTop} + ${tw`mt-10`.marginTop});
`;

const StartStyled = styled.h2`
  ${tw`pb-8 text-3xl text-primary-900 font-thin`}
`;

export { Container, StartStyled, CounterStyled };
