import React, { useEffect, useContext, memo } from 'react';

import { CreditcardContext } from '../../context/creditcard.context';
import { navigateToError, redirectWhenStatusPageIsNotValid } from '../../utils/navigate';

import { LoadingComponent } from '../../components/loading';
import { FormUserDetail } from '../../components/forms/user-detail';

import { StartStyled, CounterStyled } from '../../styles/pages/user';
import { diffTwoDatesInSeconds as diff, isTrue, isGreaterThan } from '../../utils/functions';

import pages from '../../constants/pages.json';
import Layout from '../../components/responsive/layout';
import Portrait from '../../components/responsive/portrait';
import { useStaticQuery, graphql } from 'gatsby';
import { LangContext } from '../../context/lang.context';

const RenderLayout = memo(
  ({ render, get, token }: any) => {
    if (!render) {
      return <LoadingComponent />;
    }
    const { t } = useContext(LangContext);
    const data = useStaticQuery(graphql`
      query {
        allI18NJson {
          nodes {
            locale
            clientId
            USER_DETAIL {
              TITLE_1
              TITLE_2
              TITLE_3
              TITLE_4
              TITLE_5
              PRINCIPAL_TITLE
            }
          }
        }
      }
    `);
    return (
      <Layout>
        <Portrait>
          <section className="content relative text-center">
            <h1 className="heading text-white mx-auto pt-8 pb-32" style={{ maxWidth: 300 }}>
              {t(data).USER_DETAIL.TITLE_1}
              <b>{t(data).USER_DETAIL.TITLE_2} </b>
              <br />
              {t(data).USER_DETAIL.TITLE_3}
              <br />
              <b>{t(data).USER_DETAIL.TITLE_4}</b>
              {t(data).USER_DETAIL.TITLE_5}
            </h1>
          </section>
        </Portrait>
        <section className="content">
          <CounterStyled
            get={get}
            messageMiddleFrom={pages.USER.UPDATE_USER_DETAIL}
            visible={false}
            css={{ display: 'none' }}
          />
          <StartStyled className="text-center">{t(data).USER_DETAIL.PRINCIPAL_TITLE}</StartStyled>
          <FormUserDetail get={get} token={token} />
        </section>
      </Layout>
    );
  },
  ({ render: prev }, { render: next }) => prev === next
);

const UserPage = ({ get, getToken }: PageProps) => {
  const { infoUser, timeStamp, getUser, getCompanyDetails } = useContext(CreditcardContext);
  const allValid = [Object.entries(infoUser).length, timeStamp?.length ?? 0].every(isGreaterThan(0));
  const token: string = getToken();

  useEffect(() => {
    if (!token) {
      navigateToError()?.();
    } else if (!infoUser?.expirationTime) {
      getUser?.(token);
      getCompanyDetails?.();
    }
  }, []);

  useEffect(() => {
    if (allValid) {
      const state = { expired: { state: { messageMiddleFrom: pages.USER.UPDATE_USER_DETAIL } } };
      redirectWhenStatusPageIsNotValid(infoUser, timeStamp, state);
    }
  }, [infoUser, timeStamp]);

  const render = [allValid, !!diff(timeStamp ?? '', infoUser?.expirationTime ?? ''), !infoUser.status].every(isTrue);
  return <RenderLayout render={render} get={get} token={token} />;
};

export default UserPage;
