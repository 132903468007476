import React, { ChangeEvent, useContext, useState } from 'react';
import { useFormik, FormikValues } from 'formik';
import * as Yup from 'yup';

import { FormProps } from './models';
import creditCardService from '../../services/endpoints';
import { Input, RadioSimple, RadioSimpleGroup, Select } from '../inputs';
import { ArticleStyled, SubmitStyled } from './styles';
import { CreditcardContext } from '../../context/creditcard.context';
import { validEmailRegex, validInternationalNameRegex, validNumbersRegex } from '../../utils/regexs';
import { convertFromAppToUserDetail } from '../../utils/converts';
import pages from '../../constants/pages.json';
import { navigateToSuccess, navigateToError, navigateToFailed } from '../../utils/navigate';
import { createMask, MASK_CHAR_CARD } from '../../utils/masks';
import { graphql, useStaticQuery } from 'gatsby';
import { LangContext } from '../../context/lang.context';

const taxTypeList = ['CUIT', 'CUIL'];

const provinceList = [
  'Buenos Aires',
  'Catamarca',
  'Chaco',
  'Chubut',
  'Ciudad Autonoma de Buenos Aires',
  'Cordoba',
  'Corrientes',
  'Entre Rios',
  'Formosa',
  'Jujuy',
  'La Pampa',
  'La Rioja',
  'Mendoza',
  'Misiones',
  'Neuquen',
  'Rio Negro',
  'Salta',
  'San Juan',
  'San Luis',
  'Santa Cruz',
  'Santa Fe',
  'Santiago del Estero',
  'Tierra del Fuego',
  'Tucuman',
];

const FormUserDetail = ({ token }: FormProps & { token: string }) => {
  const { t } = useContext(LangContext);

  const phoneCompanyList = (data: {}) => [
    { value: 'CLARO', title: 'Claro' },
    { value: 'MOVISTAR', title: 'Movistar' },
    { value: 'PERSONAL', title: 'Personal' },
    { value: 'OTRA', title: t(data).USER_DETAIL.FORM.PHONE_COMPANY_OTHER },
  ];

  const documentTypeList = (data: {}) => [
    { value: 'DNI', title: t(data).USER_DETAIL.FORM.DOCUMENT_TYPE_COMBO.DNI },
    { value: 'PASAPORTE', title: t(data).USER_DETAIL.FORM.DOCUMENT_TYPE_COMBO.PASSPORT },
    { value: 'CEDULA', title: t(data).USER_DETAIL.FORM.DOCUMENT_TYPE_COMBO.IDENTIFICATION_CARD },
    { value: 'LIBCIV', title: t(data).USER_DETAIL.FORM.DOCUMENT_TYPE_COMBO.CIVIC_NOTEBOOK },
    { value: 'LIBENR', title: t(data).USER_DETAIL.FORM.DOCUMENT_TYPE_COMBO.ENROLLMENT_BOOK },
  ];

  const data = useStaticQuery(graphql`
    query {
      allI18NJson {
        nodes {
          locale
          clientId
          USER_DETAIL {
            FORM {
              EMPTY_SELECT_FORM_USER_DETAIL
              EMPTY_SELECT_FORM_USER_DETAIL_COMPANY
              NAME
              LASTNAME
              DOCUMENT_TYPE
              DOCUMENT_NUMBER
              TAX_TYPE
              TAX_NUMBER
              EMAIL
              PHONE
              PHONE_COMPANY
              PHONE_COMPANY_OTHER
              LOCATION
              POSTAL_CODE
              PROVINCE
              STREET
              STREET_NUMBER
              DEPARTMENT
              SAVE
              VALIDATION {
                LANDLINE_REQUIRED
                DOCUMENT_NUMBER_INVALID_CHAR
                DOCUMENT_NUMBER_MIN_LENGTH
                DOCUMENT_TYPE_REQUIRED
                EMAIL_FORMAT_NOT_VALID
                INVALID_DOCUMENT_NUMBER
                LASTNAME_INVALID_MAX_LENGTH
                NAME_INVALID_MAX_LENGTH
                TAX_NUMBER_MIN_LENGTH
              }
              DOCUMENT_TYPE_COMBO {
                DNI
                PASSPORT
                IDENTIFICATION_CARD
                CIVIC_NOTEBOOK
                ENROLLMENT_BOOK
              }
              GENDER {
                FEMALE
                MALE
                TITLE
              }
              LANDLINE {
                COD
                TITLE
              }
            }
            PRINCIPAL_TITLE
            SUBTITLE
            TITLE_2
            TITLE_1
            TITLE_3
            TITLE_4
            TITLE_5
          }
          EXAMPLE
          CVU {
            FORM {
              VALIDATION {
                INVALID_COMPANY
                INVALID_TAXTYPE
                INVALID_NAME
                INVALID_LASTNAME
                INVALID_ALIAS
                INVALID_DOCUMENT_NUMBER
                INVALID_DOCUMENT_TYPE
                INVALID_LENGTH_ALIAS
                INVALID_TAXNUMBER_1
                INVALID_TAXNUMBER_2
              }
            }
          }
        }
      }
    }
  `);

  const { infoUser, company } = useContext(CreditcardContext);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const formik = useFormik({
    initialValues: {
      department: infoUser.department,
      documentNumber: infoUser.documentNumber,
      documentType: infoUser.documentType,
      email: infoUser.email,
      gender: infoUser.gender,
      landlineNumber: infoUser.landlineNumber,
      lastname: infoUser.lastname,
      location: infoUser.location,
      name: infoUser.name,
      phoneCompany: infoUser.phoneCompany,
      phoneNumber: infoUser.phoneNumber,
      postalCode: infoUser.postalCode,
      province: infoUser.province,
      street: infoUser.street,
      streetNumber: infoUser.streetNumber,
      taxNumber: infoUser.taxNumber,
      taxType: infoUser.taxType,
    },
    validationSchema: Yup.object({
      department: Yup.string().max(4, ' '),
      documentNumber: Yup.lazy(() => {
        const { documentType } = formik.values;
        let validation = Yup.string();

        if (documentType) {
          const minLength = documentType === 'PASAPORTE' ? 9 : 7;

          validation = validation
            .required(t(data).USER_DETAIL.FORM.VALIDATION.INVALID_DOCUMENT_NUMBER)
            .min(minLength, t(data).USER_DETAIL.FORM.VALIDATION.INVALID_DOCUMENT_NUMBER);
        }

        if (infoUser.documentNumber.length !== 0) {
          validation = validation.required(t(data).USER_DETAIL.FORM.VALIDATION.INVALID_DOCUMENT_NUMBER);
        }

        return validation;
      }),
      documentType: Yup.lazy(() => {
        let validation = Yup.mixed().oneOf([...documentTypeList(data).map(({ value }) => value)], ' ');
        if (infoUser.documentType.length !== 0) {
          validation = validation.required(t(data).USER_DETAIL.FORM.VALIDATION.DOCUMENT_TYPE_REQUIRED);
        }

        return validation;
      }),
      email: Yup.lazy(() => {
        let validation = Yup.string().max(65, ' ').matches(validEmailRegex, ' ');
        if (infoUser.email.length !== 0) {
          validation = validation.required(t(data).CUSTOMER.FORM.VALIDATION.EMAIL);
        }

        return validation;
      }),
      gender: Yup.mixed().oneOf(['MALE', 'FEMALE'], ' '),
      landlineNumber: Yup.lazy(() => {
        let validation = Yup.string().max(15, '').matches(validNumbersRegex, ' ');
        if (infoUser.landlineNumber.length !== 0) {
          validation = validation.required(t(data).USER_DETAIL.FORM.VALIDATION.LANDLINE_REQUIRED);
        }

        return validation;
      }),
      lastname: Yup.lazy(() => {
        let validation = Yup.string().max(40, ' ').matches(validInternationalNameRegex, ' ');
        if (infoUser.lastname.length !== 0) {
          validation = validation.required(t(data).CVU.FORM.VALIDATION.INVALID_LASTNAME);
        }

        return validation;
      }),
      location: Yup.string().max(40, ' '),
      name: Yup.lazy(() => {
        let validation = Yup.string().max(40, 'max').matches(validInternationalNameRegex, ' ');
        if (infoUser.name.length !== 0) {
          validation = validation.required(t(data).CVU.FORM.VALIDATION.INVALID_NAME);
        }

        return validation;
      }),
      phoneCompany: Yup.lazy(() => {
        let validation = Yup.mixed().oneOf([...phoneCompanyList(data).map(({ value }) => value)], ' ');
        if (infoUser.phoneCompany.length !== 0) {
          validation = validation.required(t(data).CVU.FORM.VALIDATION.INVALID_COMPANY);
        }

        return validation;
      }),
      postalCode: Yup.string().max(5, 'max').matches(validNumbersRegex, ''),
      province: Yup.mixed().oneOf(provinceList),
      street: Yup.string().max(25, 'max'),
      streetNumber: Yup.string().max(5, ' '),
      taxType: Yup.lazy(() => {
        let validation = Yup.mixed().oneOf([...taxTypeList], ' ');
        if (infoUser.taxType.length !== 0) {
          validation = validation.required(t(data).CVU.FORM.VALIDATION.INVALID_TAXTYPE);
        }

        return validation;
      }),
      taxNumber: Yup.lazy(() => {
        const { taxType } = formik.values;
        let validation = Yup.string()
          .max(20, ' ')
          .matches(validNumbersRegex, ' ')
          .validCuitCuil(t(data).CVU.FORM.VALIDATION.INVALID_TAXNUMBER_1);

        if (taxType) {
          validation = validation.required(t(data).CVU.FORM.VALIDATION.INVALID_TAXNUMBER_1);
        }
        if (infoUser.taxNumber.length !== 0) {
          validation = validation.required(t(data).CVU.FORM.VALIDATION.INVALID_TAXNUMBER_1);
        }

        return validation;
      }),
    }),
    onSubmit: async (values: FormikValues) => {
      if (submitting) {
        return;
      }

      setSubmitting(true);

      try {
        const paramsRequest = convertFromAppToUserDetail(values);
        await creditCardService.updateUserDetail(paramsRequest, token);
        const state = { from: pages.USER.UPDATE_USER_DETAIL };
        navigateToSuccess({ state })?.();
        setSubmitting(false);
      } catch ({ errorCode }) {
        if (errorCode === 'FGS1500') {
          return navigateToFailed({
            state: { from: pages.FAILED_PAGE.DUPLICATE_USER },
          })?.();
        }
        navigateToError()?.();
      }
    },
  });

  const { documentType } = formik.values;
  let mask = createMask('NUMBER', 8);

  if (documentType === 'PASAPORTE') {
    mask = createMask('PASSPORT', 9);
  }

  return (
    <form className="w-full" autoComplete="false" onSubmit={formik.handleSubmit}>
      <ArticleStyled>
        <Input
          title={t(data).USER_DETAIL.FORM.NAME}
          errorMessage={formik.errors.name}
          onChange={formik.handleChange('name')}
          onFocus={() => formik.setFieldTouched('name', false)}
          touchedInput={formik.touched.name}
          mask={createMask('NAME', 40)}
          placeholderChar={MASK_CHAR_CARD}
          guide={false}
          value={formik.values.name}
        />
      </ArticleStyled>

      <ArticleStyled>
        <Input
          title={t(data).USER_DETAIL.FORM.LASTNAME}
          errorMessage={formik.errors.lastname}
          onChange={formik.handleChange('lastname')}
          onFocus={() => formik.setFieldTouched('lastname', false)}
          touchedInput={formik.touched.lastname}
          value={formik.values.lastname}
          mask={createMask('NAME', 40)}
          placeholderChar={MASK_CHAR_CARD}
          guide={false}
        />
      </ArticleStyled>

      <ArticleStyled>
        <Select
          title={t(data).USER_DETAIL.FORM.DOCUMENT_TYPE}
          options={documentTypeList(data)}
          onChange={(e: ChangeEvent<any>) => {
            formik.setFieldValue('documentType', e.target.value);
            formik.setFieldTouched('documentType', false);
          }}
          touchedInput={formik.touched.documentType}
          errorMessage={formik.errors.documentType}
          defaultOption={formik.values.documentType}
          emptySelect={t(data).USER_DETAIL.FORM.EMPTY_SELECT_FORM_USER_DETAIL}
          removeDefaultValue
        />
      </ArticleStyled>

      <ArticleStyled>
        <Input
          title={t(data).USER_DETAIL.FORM.DOCUMENT_NUMBER}
          errorMessage={formik.errors.documentNumber}
          onChange={formik.handleChange('documentNumber')}
          onFocus={() => formik.setFieldTouched('documentNumber', false)}
          touchedInput={formik.touched.documentNumber}
          value={formik.values.documentNumber}
          mask={mask}
          placeholderChar={MASK_CHAR_CARD}
          guide={false}
          suffixIcon={true}
        />
      </ArticleStyled>

      <ArticleStyled>
        <Select
          title={t(data).USER_DETAIL.FORM.TAX_TYPE}
          options={taxTypeList}
          onChange={(e: ChangeEvent<any>) => {
            formik.setFieldValue('taxType', e.target.value);
            formik.setFieldTouched('taxType', false);
          }}
          touchedInput={formik.touched.taxType}
          errorMessage={formik.errors.taxType}
          defaultOption={formik.values.taxType}
          emptySelect={t(data).USER_DETAIL.FORM.EMPTY_SELECT_FORM_USER_DETAIL}
          removeDefaultValue
        />
      </ArticleStyled>

      <ArticleStyled>
        <Input
          title={t(data).USER_DETAIL.FORM.TAX_NUMBER}
          errorMessage={formik.errors.taxNumber}
          onChange={formik.handleChange('taxNumber')}
          onFocus={() => formik.setFieldTouched('taxNumber', false)}
          touchedInput={formik.touched.taxNumber}
          value={formik.values.taxNumber}
          mask={createMask('NUMBER', 11)}
          placeholderChar={MASK_CHAR_CARD}
          guide={false}
        />
      </ArticleStyled>

      <ArticleStyled touchedInputInside={formik.touched.gender} errorMessageInside={formik.errors.gender}>
        <RadioSimpleGroup
          className="grid gap-6 grid-cols-2 items-center h-full px-1"
          title={t(data).USER_DETAIL.FORM.GENDER.TITLE}
        >
          <RadioSimple
            group="gender"
            title={t(data).USER_DETAIL.FORM.GENDER.FEMALE}
            value="FEMALE"
            checked={formik.values.gender === 'FEMALE'}
            onChange={(e: ChangeEvent<any>) => {
              formik.setFieldValue('gender', e.target.value);
              formik.setFieldTouched('gender', false);
            }}
          />

          <RadioSimple
            group="gender"
            title={t(data).USER_DETAIL.FORM.GENDER.MALE}
            value="MALE"
            checked={formik.values.gender === 'MALE'}
            onChange={(e: ChangeEvent<any>) => {
              formik.setFieldValue('gender', e.target.value);
              formik.setFieldTouched('gender', false);
            }}
          />
        </RadioSimpleGroup>
      </ArticleStyled>

      <ArticleStyled>
        <Input
          title={t(data).USER_DETAIL.FORM.EMAIL}
          errorMessage={formik.errors.email}
          onChange={formik.handleChange('email')}
          onFocus={() => formik.setFieldTouched('email', false)}
          touchedInput={formik.touched.email}
          value={formik.values.email}
          type="mail"
        />
      </ArticleStyled>

      <ArticleStyled>
        <Input
          title={t(data).USER_DETAIL.FORM.PHONE}
          className="col-span-1"
          value={formik.values.phoneNumber}
          onChange={() => {}}
          placeholder={`${t(data).EXAMPLE} ${company?.cellPhoneFormat}`}
          placeholderPosition="left"
          disabled
        />
      </ArticleStyled>

      <ArticleStyled>
        <Select
          title={t(data).USER_DETAIL.FORM.PHONE_COMPANY}
          options={phoneCompanyList(data)}
          onChange={(e: ChangeEvent<any>) => {
            formik.setFieldValue('phoneCompany', e.target.value);
            formik.setFieldTouched('phoneCompany', false);
          }}
          touchedInput={formik.touched.phoneCompany}
          errorMessage={formik.errors.phoneCompany}
          defaultOption={formik.values.phoneCompany}
          emptySelect={t(data).USER_DETAIL.FORM.EMPTY_SELECT_FORM_USER_DETAIL_COMPANY}
          removeDefaultValue
        />
      </ArticleStyled>
      <ArticleStyled>
        <Input
          title={t(data).USER_DETAIL.FORM.LOCATION}
          errorMessage={formik.errors.location}
          onChange={formik.handleChange('location')}
          onFocus={() => formik.setFieldTouched('location', false)}
          touchedInput={formik.touched.location}
          value={formik.values.location}
          mask={createMask('NAME', 40)}
          placeholderChar={MASK_CHAR_CARD}
          guide={false}
        />
      </ArticleStyled>
      <ArticleStyled>
        <Input
          title={t(data).USER_DETAIL.FORM.POSTAL_CODE}
          errorMessage={formik.errors.postalCode}
          onChange={formik.handleChange('postalCode')}
          onFocus={() => formik.setFieldTouched('postalCode', false)}
          touchedInput={formik.touched.postalCode}
          value={formik.values.postalCode}
          mask={createMask('NUMBER', 5)}
          guide={false}
        />
      </ArticleStyled>
      <ArticleStyled>
        <Select
          title={t(data).USER_DETAIL.FORM.PROVINCE}
          options={provinceList}
          onChange={(e: ChangeEvent<any>) => {
            formik.setFieldValue('province', e.target.value);
            formik.setFieldTouched('province', false);
          }}
          touchedInput={formik.touched.province}
          errorMessage={formik.errors.province}
          defaultOption={formik.values.province}
          emptySelect={t(data).USER_DETAIL.FORM.EMPTY_SELECT_FORM_USER_DETAIL}
          removeDefaultValue
        />
      </ArticleStyled>
      <ArticleStyled>
        <Input
          title={t(data).USER_DETAIL.FORM.STREET}
          errorMessage={formik.errors.street}
          onChange={formik.handleChange('street')}
          onFocus={() => formik.setFieldTouched('street', false)}
          touchedInput={formik.touched.street}
          value={formik.values.street}
          mask={createMask('NUMBER_CHAR', 25)}
          guide={false}
        />
      </ArticleStyled>

      <ArticleStyled>
        <div className="grid gap-2 grid-cols-2">
          <Input
            title={t(data).USER_DETAIL.FORM.STREET_NUMBER}
            errorMessage={formik.errors.streetNumber}
            onChange={formik.handleChange('streetNumber')}
            onFocus={() => formik.setFieldTouched('streetNumber', false)}
            touchedInput={formik.touched.streetNumber}
            value={formik.values.streetNumber}
            mask={createMask('NUMBER_CHAR', 5)}
            guide={false}
          />
          <Input
            title={t(data).USER_DETAIL.FORM.DEPARTMENT}
            errorMessage={formik.errors.department}
            onChange={formik.handleChange('department')}
            onFocus={() => formik.setFieldTouched('department', false)}
            touchedInput={formik.touched.department}
            value={formik.values.department}
            mask={createMask('NUMBER_CHAR', 4)}
            guide={false}
          />
        </div>
      </ArticleStyled>

      <ArticleStyled>
        <Input
          title={t(data).USER_DETAIL.FORM.LANDLINE.TITLE}
          errorMessage={formik.errors.landlineNumber}
          onChange={formik.handleChange('landlineNumber')}
          onFocus={() => formik.setFieldTouched('landlineNumber', false)}
          touchedInput={formik.touched.landlineNumber}
          value={formik.values.landlineNumber}
          mask={createMask('NUMBER', 15)}
          placeholderPosition="left"
          placeholder={`${t(data).EXAMPLE}` + company?.telephoneFormat}
          guide={false}
        />
      </ArticleStyled>

      <ArticleStyled className="items-center">
        <SubmitStyled type="submit">{t(data).USER_DETAIL.FORM.SAVE}</SubmitStyled>
      </ArticleStyled>
    </form>
  );
};

export { FormUserDetail };
